<template>
  <layout-modal
    :classes="classes"
    visible
    closable
    :loading="loading"
    @close="close">

    <!-- Loading -->
    <div
      v-if="loading"
      class="user__loader">
      <ui-loader />
    </div>

    <article
      class="view-user-order__item view-user-order__intro"
      v-if="!loading">
      <h1 class="view-user-order__title">{{ $t('resto.user_view_order_title') }}</h1>
      <h2 class="view-user-order__subtitle">{{ $t('resto.user_view_order_description') }}</h2>

      <!-- Code -->
      <dl class="view-user-order__term view-user-order__code">
        <dt>{{ $t('resto.order_code', { code }) }}</dt>
      </dl>

      <!-- Payment method -->
      <dl
        v-if="paymentMethod"
        class="view-user-order__term view-user-order__pm">
        <dt>{{ $t('resto.user_view_order_pm') }}</dt>
        <dd>{{ paymentMethod }}</dd>
      </dl>

      <!-- Comment -->
      <dl
        v-if="comment"
        class="view-user-order__term view-user-order__comment">
        <dt>{{ $t('resto.checkout_review_comment') }}</dt>
        <dd>{{ comment }}</dd>
      </dl>
    </article>

    <!-- Cart -->
    <article
      class="view-user-order__item view-user-order__cart"
      v-if="!loading">
      <cart
        :value="order"
        :sub-orders="isMultiShop"
      />
    </article>

    <!-- Reward -->
    <article
      class="view-user-order__item view-user-order__loyalty"
      v-if="hasLoyaltyReward">
      <div class="view-user-order__loyalty-badge">
        <ui-icon
          class="view-user-order__loyalty-icon"
          glyph="gift"
        />

        <div class="view-user-order__loyalty-label">{{ $t('resto.confirm_loyalty_reward', { points: loyaltyReward }) }}</div>
      </div>
    </article>

    <article
      class="view-user-order__item"
      v-if="!loading">
      <div class="row">
        <h2 class="view-user-order__title">{{ service }}</h2>
      </div>

      <div class="group">
        <!-- Collect Location -->
        <div class="view-user-order__location">
          <div
            class="view-user-order__label"
            v-if="isAsap">{{ $t(`resto.user_order_is_asap`) }}</div>

          <div
          class="view-user-order__label"
          v-if="!isAsap">{{ $t(`resto.user_order_${serviceName}_date`, {date: completionDate}) }}</div>

          <address class="view-user-order__address" v-html="pickupAddress"></address>

          <!-- Direction
          <div class="row">
            <actions-button
              appearance="subtle"
              class="view-user-order__cta"
              :href="pickupAddressLink"
              icon-pre="open"
              size="s">{{ $t('resto.get_direction') }}</actions-button>
          </div> -->

          <!-- Phone -->
          <div
            v-if="phone"
            class="row">
            <actions-button
              appearance="subtle"
              :href="phoneLink"
              icon-pre="phone"
              class="view-user-order__cta"
              size="s">{{ phone }}</actions-button>
          </div>

        </div>

        <!-- Collect Time -->
        <div class="view-user-order__time">
          <div class="view-user-order__label"></div>
        </div>
      </div>
    </article>

    <template v-slot:footer>
      <actions-button @click="close">Close</actions-button>
      <actions-button
        v-if="false"
        :appearance="$pepper.Appearance.PRIMARY"
        @click="close">Reorder</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { mapState } from 'vuex'
import LayoutModal from '@/components/layouts/modal'
import Cart from '@/modules/checkout/components/cart/cart'
import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'UserOrderView',

  inject: [
    '$embed',
    '$loyalty',
    '$user'
  ],

  components: {
    Cart,
    LayoutModal
  },

  mixins: [
    MixinMultishop,
  ],

  data() {
    return {
      loading: false,
      order: null
    }
  },

  computed: {
    ...mapState({
      eatin: state => state['sayl-front.service-eatin'],
    }),

    classes() {
      return {
        'view-user-order': true
      }
    },

    completionDate() {
      let ret = null
      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)

        ret = this.$date(v)
      }
      return ret
    },

    orderAddress() {
      return this.flpos.address || this.shop.address
    },

    tables() {
      let ret = [];
      if(this.eatin) {
        ret = this.eatin.tables;
      }
      return ret;
    },

    flpos() {
      let ret = this.shop.flposes.find(fl => fl.id === this.$basil.get(this.order, 'fulfillment_location_id'))
      return ret
    },

    code() {
      return this.$basil.get(this.order, 'pickup_code', null)
    },

    comment() {
      return this.$basil.get(this.order, 'comment')
    },

    deliveryMethod() {
      return this.order && this.order.delivery_method && this.order.delivery_method.name + ' (+' + this.$n(this.order.delivery_method.price, 'currency') + ')'
    },

    deliveryAddress() {
      let ret = '';

      if(this.order && this.order.delivery_address) {
        ret = `${this.order.delivery_address.number}, ${this.order.delivery_address.street} <br/>`
        ret += `${this.order.delivery_address.zip} ${this.order.delivery_address.city} ${this.order.delivery_address.country}`
      }
      return ret
    },

    hasLoyaltyReward() {
      let rew = this.$basil.get(this.order, 'credits.reward', null)
      return !this.$basil.isNil(rew)
    },

    isAsap() {
      return this.$basil.get(this.order, 'pickup_asap', false)
    },

    loyaltyReward() {
      let reward = this.$basil.get(this.order, 'credits.reward', null)

      if(!this.$basil.isNil(reward)) {
        let int = parseInt(reward, 10)
        let float = (reward - int).toFixed(2)
        if(float > 0 && float < 1) {
          reward = parseFloat(reward, 10).toFixed(2)
        } else {
          reward = parseInt(reward, 10)
        }
      }

      return reward + this.rewardSymbol
    },

    orderTimeslot() {
      return this.$basil.get(this.order, 'timeslot', null)
    },

    paymentMethod() {
      let ret = null

      let slug = this.$basil.get(this.order, 'payment_method')
      if (!this.$basil.isNil(slug)){
        ret = this.$t(`resto.payment_methods_${slug}`)
      }

      return ret;
    },

    phone() {
      return this.$basil.get(this.flpos, 'phone')
    },

    pickupAddress() {
      let ret = null;
      let address = null;

      if(this.flpos) {
        ret = this.$basil.get(this.flpos, 'name');
        address = this.$basil.get(this.flpos, 'address');

        if (address){
          ret += `<br>${address}`
        }
      } else if(this.shop){
        ret = this.$basil.get(this.shop, 'name');
        address = this.$basil.get(this.shop, 'address');

        if (address){
          ret += `<br>${address}`
        }
      }

      return ret;
    },

    rewardSymbol() {
      return this.$basil.get(this.$loyalty, 'program.points.symbol', null)
    },

    service() {
      let n = this.serviceName === 'pickup' ? 'base' : this.serviceName
      return this.$t(`resto.service_${n}`)
    },

    serviceName() {
      let ret = this.$basil.get(this.order, 'service')
      return ret === 'base' ? 'pickup' : ret
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    phoneLink() {
      return `tel://${this.phone}`
    },
  },

  methods: {
    close() {
      this.$router.push({ name: 'sayl-front-user.orders' }).catch(() => {})
    },

    reset() {
      this.loading = true

      this.$user.viewOrder({ id: this.$route.params.id })
        .then((order) => this.order = order)
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
